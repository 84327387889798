@import "./mixin.scss";
@import "./variable.scss";

.gallery {
    display: grid;
    grid-gap: 10px;
  
    /* fill the page's width with columns, each having from about 200 to 400 px */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  
    /* explicitly set the row heights */
    grid-template-rows: repeat(1, 250px) repeat(1, 150px);
  
    /* from MDN: "fill in holes earlier in the grid, if smaller items come up later" */
    grid-auto-flow: dense;
  }
  
  .item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    /* for larger screens... */
    @media (min-width: 480px) {
  
      /* first item will have size 2x2 */
      &:first-child {
        grid-area: 1 / 1 / span 2 / span 2;
      }
  
      /* and every third item will have size 2x1 */
      &:nth-child(3n) {
        grid-column: span 2;
      }
  
    }
  }

  .show-more-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-image: linear-gradient(45deg, #7B60EF, #5A98F2);
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .show-more-btn:hover {
    background-image: linear-gradient(45deg, #5A98F2, #7B60EF);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .show-more-btn:focus {
    outline: none;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  .show-more-btn:active {
    background-image: linear-gradient(45deg, #7B60EF, #5A98F2);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
  }

  