@import "../common/mixin.scss";
@import "../common/variable.scss";

.blog-post {
  position: relative;
  width: calc(33.33% - 27px);
  padding: 3px;
  height: auto;
  border-radius: 14px;
  z-index: 1111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;

  @include mq("phone-and-tablet") {
    width: calc(50% - 10px);
  }
  @include mq("small-phone") {
    width: calc(100%);
  }

  .bg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    z-index: 2;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
  }

  .blob {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $color-primary;
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
  }
  .blog-content {
    h4 {
      width: 100%;
      /* this code clamps based on specified lines */
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
  }
  .blog-button {
    font-family: inherit;
    height: auto;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    font-size: 16px;
    z-index: 1;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 6px;
    position: relative;
    padding: 10px 15px;
    margin-right: 0;
    margin-left: auto;
    display: block;
    width: fit-content;

    &::before {
      position: absolute;
      content: "";
      background: $color-primary;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 50%;
      top: 100%;
      left: 100%;
      transition: 0.3s all;
    }

    &:hover {
      color: white;

      &::before {
        top: 0;
        left: 0;
        border-radius: 0;
      }
    }
  }
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
  }
}
