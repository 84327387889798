@import "./mixin.scss";
@import "./variable.scss";

// Define variables (you need to replace these with actual values from your setup)
$colorBubbel: #fff; // Example color
$timeline: #444343; // Example color
$timelineActive: #7161ef; // Example color
$fontBase: Arial, sans-serif; // Example font
$fontHead: "Helvetica Neue", Helvetica, Arial, sans-serif; // Example font

.timeline {
  ul {
    background-size: 8px 8px;
    padding: 0px 0px 50px;

    li {
      background: $timeline;
      font-family: $fontBase;
      list-style-type: none;
      padding-top: 50px;
      position: relative;
      margin: 0 auto;
      width: 4px;

      @include mq("phone-and-tablet") {
        margin: 0 auto 0 20px;
      }

      &::after {
        background: inherit;
        border-radius: 50%;
        bottom: 7px;
        content: "";
        height: 15px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 15px;
      }
      &:last-child::after {
        bottom: 0;
      }

      div {
        background: $colorBubbel;
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
        bottom: 0;
        color: $timeline;
        position: relative;
        padding: 15px 20px 20px;
        width: 400px;

        &::before {
          border-style: solid;
          bottom: 7px;
          content: "";
          position: absolute;
          height: 0;
          width: 0;
        }
      }
      @include mq("tablet-up") {
        &:nth-child(odd) div {
          left: 45px;

          &::before {
            border-width: 8px 16px 8px 0;
            border-color: transparent $colorBubbel transparent transparent;
            left: -12px;
          }
        }

        &:nth-child(even) div {
          left: -439px;

          &::before {
            border-width: 8px 0 8px 16px;
            border-color: transparent transparent transparent #fff;
            right: -12px;
            bottom: 7px;
          }

          &::after {
            border-width: 8px 0 8px 16px;
            border-color: transparent transparent transparent $colorBubbel;
            right: -15px;
          }
        }

        &:nth-child(odd) div {
          transform: translate3d(200px, 0, 0);
        }

        &:nth-child(even) div {
          transform: translate3d(-200px, 0, 0);
        }
      }

      &::after {
        transition: background 0.5s ease-in-out;
      }

      &.inView::after {
        background: $timelineActive;
      }

      div {
        opacity: 0;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

        @include mq("phone-and-tablet") {
          left: 45px;
          transform: translate3d(200px, 0, 0);
        }
        @include mq("small-phone") {
          left: 25px;
          width:290px
        }
      }

      &.inView div {
        opacity: 1;
        transform: none;
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .timeline {
      ul {
        li div {
          width: 250px;
        }

        li:nth-child(even) div {
          left: -289px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .timeline {
      ul {
        li {
          margin-left: 20px;

          div {
            width: calc(100vw - 91px);

            &:nth-child(even) {
              left: 45px;

              &::after {
                border-width: 8px 16px 8px 0;
                border-color: transparent $colorBubbel transparent transparent;
                left: -15px;
              }

              &::before {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
