@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./mixin.scss";
@import "./variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: #e8f1ff;
}

html:has(.menu-open) {
  overflow: hidden;
}
body:has(.popup-open) {
  overflow: hidden;
  .popup-backdrop {
    display: block;
  }
}

.scrollbarNone {
  &::-webkit-scrollbar {
    display: none;
  }
}

.popup-backdrop {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #7161ef; /* Set the color of the thumb */
  border-radius: 6px; /* Set the border radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the color of the track */
}

h1 {
  font-size: 36px;
  font-style: normal;
  // font-weight: 700;
  line-height: 1.2;

  @include mq("mid-lap") {
    font-size: 36px;
  }
  @include mq("tablet") {
    font-size: 30px;
  }
  @include mq("phone") {
    font-size: 26px;
  }
}
h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;

  @include mq("mid-lap") {
    font-size: 32px;
  }
  @include mq("tablet") {
    font-size: 28px;
  }
  @include mq("phone") {
    font-size: 24px;
  }
}
h3 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;

  @include mq("mid-lap") {
    font-size: 20px;
  }
  @include mq("tablet") {
    font-size: 18px;
  }
  @include mq("phone") {
    font-size: 16px;
  }
}
h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;

  @include mq("mid-lap") {
    font-size: 18px;
  }
  @include mq("tablet") {
    font-size: 16px;
  }
  @include mq("phone") {
    font-size: 14px;
  }
}

.header {
  background: #e8f1ff;
  display: block;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);

  &.is-scroll{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.header-container {
  max-width: 1280px;
  padding: 18px 32px;
  width: 100%;
  margin: 0 auto;

  @include mq("phone-and-tablet") {
    padding: 18px 16px;
  }
}
.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @include mq("phone-and-tablet") {
    padding: 0 16px;
  }
}

.headerHamburger {
  width: 25px;
  height: 2px;
  background-color: #000;
  border-radius: 10px;
  display: block;
  position: relative;
  top: 7px;
  transition: 0.3s;

  &:after,
  &:before {
    content: "";
    height: 2px;
    background-color: #000;
    border-radius: 10px;
    position: absolute;
    left: 0;
    transition: 0.3s;
  }

  &:after {
    width: 25px;
    top: -14px;
  }
  &:before {
    width: 20px;
    top: -7px;
    left: 5px;
  }

  &.active {
    transform: rotate(-45deg) translateY(-5px) translateX(5px);

    &:before {
      opacity: 0;
    }
    &:after {
      transform: rotate(90deg) translateX(14px);
    }
  }
}

.section {
  min-height: 100vh;
}
.section-padding {
  padding-top: 70px;
  padding-bottom: 70px;

  @include mq("phone-and-tablet") {
    padding: 40px 0;
  }
}

.menu-link {
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    transition: 0.3s;
    transform: scale(0);
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: $color-primary;

    @include mq("phone-and-tablet") {
      display: none;
    }
  }

  &:hover,
  &.active {
    color: $color-primary;
    &:after {
      transform: scale(1);
    }
  }
}

// Scroll Animation
#scroll-down-animation {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 2px solid $color-primary;
  height: 50px;
  width: 30px;
  position: relative;
}

.move {
  position: absolute;
  background-color: $color-primary;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(-50%, 15px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 25px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 35px);
    opacity: 0;
  }
}

// Form Input
.normal-input {
  border: 1px solid $color-primary;
  height: 40px;
  border-radius: 6px;
  padding: 8px 16px;
  outline: none;
  width: 100%;
  font-size: 16px;

  @include mq("phone-and-tablet") {
    padding: 6px 12px;
    height: 40px;
    font-size: 14px;
  }
}
.input-wrapper {
  &:not(:first-child) {
    margin-top: 15px;
  }
}
.input-tag {
  @apply border border-[#758092] rounded-md lg:text-sm text-xs font-medium;

  margin: 0;
  font-family: "Inter", sans-serif;
  color: #263a43;
  outline-color: $color-primary;
}
.input-label {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #263a43;

  @include mq("phone-and-tablet") {
    font-size: 14px;
  }
}
// input label read only
.input-label-read-only {
  padding: 12px 15px 0;
  margin: 0;
  background-color: #fafafa;
  text-transform: capitalize;
  color: #000;
  font-family: "Monda", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  border-radius: 6px 6px 0 0;
  display: block;
  padding-bottom: 5px;

  @include mq("lap-to-small-desk") {
    font-size: 14px;
  }

  @include mq("phone-and-tablet") {
    padding: 8px 11px 0;
    font-size: 12px;

    @include mq("phone") {
      font-size: 10px;
    }
  }
}
// input read only
.input-tag[readonly] {
  font-family: "Monda", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border: none;
  padding: 0px 15px 12px;
  background-color: #fafafa;
  border-radius: 0 0 6px 6px;
  height: 30px;
  &::placeholder {
    color: #000;
  }
  &:focus {
    outline: none;
  }
  @include mq("mid-lap") {
    font-size: 14px;
  }
  @include mq("phone-and-tablet") {
    padding: 0 11px 8px;
    font-size: 12px;

    @include mq("phone") {
      font-size: 10px;
    }
  }
}
input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
}

.highlighted-text {
  background: linear-gradient(183deg, #7b60ef -8.57%, #5a98f2 82.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-underline {
  position: relative;
  color: #000;

  &:after {
    content: "";
    display: inline-block;
    height: 2px;
    width: 50px;
    background-color: black;
    margin-top: 2px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);


    @media (min-width: 1024px) {
      margin-top: 4px;
    }
  }
}
